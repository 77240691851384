import React from "react";
import { graphql } from "gatsby";

import Correction from "../ui/article/Correction";
import Layout from "../components/Layout";

const CorrectionPage = (props) => {
  const { data, pageContext } = props;
  const { correctionId } = pageContext;
  const correction = (
    <Correction
      article={data.micropubApp.manuscript}
      correctionId={correctionId}
    />
  );
  return <Layout children={correction} />;
};

export const query = graphql`
  query($id: ID!) {
    micropubApp {
      manuscript(id: $id) {
        id
        submissionTypes
        doi
        pmcId
        pmId
        species
        corrections {
          doi
          description
          date
          correctionType
        }
        versions {
          id
          decisionLetter
          decision
          submitted
          abstract
          acknowledgements
          authors {
            affiliations
            credit
            email
            firstName
            lastName
            submittingAuthor
            correspondingAuthor
            equalContribution
            WBId
            orcid
          }
          comments
          disclaimer
          funding
          image {
            name
            url
          }
          imageCaption
          imageTitle
          laboratory {
            name
            WBId
          }
          methods
          reagents
          patternDescription
          references {
            reference
            pubmedId
            doi
          }
          suggestedReviewer {
            name
            WBId
          }
          title
          reviews {
            reviewer {
              displayName
            }
            openAcknowledgement
            status {
              submitted
            }
          }
        }
      }
    }
  }
`;

export default CorrectionPage;
